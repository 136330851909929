import './index.html';

import './assets/fonts/Barlow-Thin.ttf';
import './assets/fonts/Barlow-Light.ttf';
import './assets/fonts/Barlow-Regular.ttf';
import './assets/fonts/Barlow-SemiBold.ttf';

// CSS
import "../node_modules/milligram/dist/milligram.css";
import './index.scss';

// JS
import "./plugins/fontawesome"

import "./partials/global"
import './partials/hero-canvas/hero-canvas';
import './partials/header/header';
import './partials/contact/contact';
import './partials/techs/techs';
