const canvas = document.getElementById('hero-canvas') as HTMLCanvasElement
const ctx = canvas.getContext('2d')

const colors = ['white', '#ffdf80', '#ff8280', '#80e5ff'] as const
type BitColor = typeof colors[number]

type Bit = {
  value: number
  position: { x: number; y: number }
  fontSize: number
  color: BitColor
}

const BIT_FLIP_PROBABILITY = 0.06
const FPS = 10

class HeroCanvas {
  static bits: Array<Bit> = []
  static shouldAnimate = true
  static lastWidth = document.documentElement.clientWidth

  constructor() {
    window.addEventListener('resize', () => {
      if (document.documentElement.clientWidth != HeroCanvas.lastWidth) {
        HeroCanvas.resetCanvas()
        HeroCanvas.lastWidth = document.documentElement.clientWidth
      }
    })
    HeroCanvas.resetCanvas()

    window.addEventListener('scroll', () => {
      HeroCanvas.shouldAnimate = window.pageYOffset < canvas.height
    })

    HeroCanvas.animate()
  }

  static generateBits(count: number, minFontSize: number, maxFontSize) {
    HeroCanvas.bits = []
    for (let i = 0; i < count; i++) {
      HeroCanvas.bits.push({
        value: randomInt(0, 1),
        position: {
          x: randomInt(0, canvas.width),
          y: randomInt(0, canvas.height),
        },
        fontSize: randomInt(minFontSize, maxFontSize, pow6),
        color: colors[randomInt(0, colors.length, pow10)],
      })
    }
  }

  static resetCanvas() {
    canvas.width =
      document.documentElement.clientWidth * window.devicePixelRatio
    canvas.height =
      document.documentElement.clientHeight * window.devicePixelRatio * 1.5

    const density = 3 / (10000 * window.devicePixelRatio)
    const volume = canvas.width * canvas.height
    const numBits = volume * density

    HeroCanvas.generateBits(
      numBits,
      5 * window.devicePixelRatio,
      22 * window.devicePixelRatio
    )
  }

  static draw = debounce(FPS, () => {
    if (!HeroCanvas.shouldAnimate) return

    ctx.clearRect(0, 0, canvas.width, canvas.height)

    HeroCanvas.bits.forEach(HeroCanvas.randomBitFlip)
    HeroCanvas.bits.forEach(HeroCanvas.drawBit)
  })

  static animate() {
    HeroCanvas.draw()
    window.requestAnimationFrame(HeroCanvas.animate)
  }

  static drawBit(bit: Bit) {
    ctx.font = `bold ${bit.fontSize}px monospace`
    ctx.fillStyle = bit.color
    ctx.fillText(bit.value === 1 ? '1' : '0', bit.position.x, bit.position.y)
  }

  static randomBitFlip(bit: Bit) {
    if (Math.random() < BIT_FLIP_PROBABILITY) {
      bit.value = bit.value === 0 ? 1 : 0
    }
  }
}

//TODO make canvas size fitting: 1.75vh

function debounce(fps: number, fn: Function) {
  const delay = 1000 / fps
  let shouldExecute = true
  setInterval(() => (shouldExecute = true), delay)

  return () => {
    if (shouldExecute) {
      fn()
      shouldExecute = false
    }
  }
}

function randomInt(min: number, max: number, distribution = (x: number) => x) {
  return Math.round(min + distribution(Math.random()) * (max - min))
}

function pow6(x: number) {
  return x * x * x * x * x * x
}

function pow10(x: number) {
  return x * x * x * x * x * x * x * x * x * x
}

new HeroCanvas()
