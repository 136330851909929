import * as lax from 'lax.js'
import * as ScrollMagic from 'scrollmagic'
import * as AOS from 'aos'


// init aos
AOS.init()

// init scrollmagic
const controller = new ScrollMagic.Controller({})

// init lax
window.addEventListener('resize', function () {
  if (lastWidth != document.documentElement.clientWidth) {
    updateLax()
  }
})

let lastWidth = document.documentElement.clientWidth
function updateLax() {
  lastWidth = document.documentElement.clientWidth
  lax.updateElements()
}

window.onload = function () {
  lax.setup() // init

  const updateLax = () => {
    lax.update(window.scrollY)
    window.requestAnimationFrame(updateLax)
  }

  window.requestAnimationFrame(updateLax)
}

export default controller
