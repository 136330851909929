import controller from "../global";
import * as ScrollMagic from "scrollmagic";

new ScrollMagic.Scene({
  triggerElement: "#email-container",
  triggerHook: -0.1
})
  .setClassToggle("#email-label, #contact-description", "active")
  .addTo(controller);



