import * as ScrollMagic from "scrollmagic";
import controller from "../global";

const sections = ["start", "techs", "projects", "about", "contact"];

function createOptions(sectionId: string) {
  const elm = document.getElementById(sectionId);

  return {
    triggerElement: `#${sectionId}`,
    duration: () => elm.scrollHeight,
    triggerHook: 0.8,
  };
}

sections.forEach((section) => {
  new ScrollMagic.Scene(createOptions("section-" + section))
    .setClassToggle("#link-" + section, "active")
    .addTo(controller);
});
